import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import {
  allCountries,
  allCodeCountries,
  allArrivals,
  allAims,
  allTranspotTypes,
  codeVerification,
  sendVerificationOnEmail,
  sendForm,
  dataForm,
} from '@/api/services';
import { setToLS, getFromLS, isLSHasItem } from '@/library/helpers';

Vue.use(Vuex);

export default new Vuex.Store({
  state() {
    return {
      leng: localStorage.getItem('multi_lang') || 'en',
    };
  },
  getters: {
    getLeng: (state) => state.leng,
  },
  actions: {
    async getAllCountries() {
      try {
        const res = await allCountries(this.state.leng);
        return res.data.countries;
      } catch (e) {
        console.log(e, 'e.error');
      }
    },
    async getAllCodeCountries() {
      try {
        const res = await allCodeCountries(this.state.leng);
        return res.data.codes;
      } catch (e) {
        console.log(e, 'e.error');
      }
    },
    async getAllArrivals() {
      try {
        const res = await allArrivals(this.state.leng);
        return res.data.regions;
      } catch (e) {
        console.log(e, 'e.error');
      }
    },
    async getAllAims() {
      try {
        const res = await allAims(this.state.leng);
        return res.data.trip_aims;
      } catch (e) {
        console.log(e, 'e.error');
      }
    },
    async getAllTranspotTypes() {
      try {
        const res = await allTranspotTypes(this.state.leng);
        return res.data.types;
      } catch (e) {
        console.log(e, 'e.error');
      }
    },
    async sendCodeVerification(_, el) {
      try {
        const res = await sendVerificationOnEmail(el);
        return res;
      } catch (e) {
        return e.response;
      }
    },
    async isValidCodeVerification(_, el) {
      try {
        const res = await codeVerification(el);
        return res;
      } catch (e) {
        return e.response;
      }
    },
    async sendFormData(_, el) {
      try {
        const res = await sendForm(el);
        console.log(res);
        return res;
      } catch (e) {
        return e.response;
      }
    },
    async getFormData(_, el) {
      try {
        const res = await dataForm(el);
        return res;
      } catch (e) {
        return e.response;
      }
    },
  },
  mutations: {
    SET_LANG(state, leng) {
      state.leng = leng;
    },
  },
});
