<template>
  <div id="app" data-app>
    <router-view />
  </div>
</template>

<script>
export default {
  comments: {},
  mounted() {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      document.addEventListener(
        'touchmove',
        (e) => {
          if (e.scale !== 1) {
            e.preventDefault();
          }
        },
        { passive: false }
      );
    }
  },
};
</script>

<style lang="sass">
@import '@/assets/styles/main.sass'
</style>
