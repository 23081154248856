import { Axios } from '@/api/config';

const getHeader = (loc = 'en') => {
  const header = (Axios.defaults.headers.common['Locale'] = loc);
  return header;
};

export const allCountries = (loc) => {
  getHeader('en');
  const url = `/form/options/countries`;
  return Axios.get(url);
};

export const allCodeCountries = (loc) => {
  getHeader('en');
  const url = `/form/options/countries-phone-codes`;
  return Axios.get(url);
};

export const allArrivals = (loc) => {
  getHeader('en');
  const url = `/form/options/regions`;
  return Axios.get(url);
};

export const allAims = (loc) => {
  getHeader('en');
  const url = `/form/options/trip-aims`;
  return Axios.get(url);
};

export const allTranspotTypes = (loc) => {
  getHeader('en');
  const url = `/form/options/types-of-transport`;
  return Axios.get(url);
};

export const codeVerification = (data) => {
  const url = `/form/validate-verification`;
  return Axios.post(url, data);
};

export const sendVerificationOnEmail = (data) => {
  const url = `/form/send-verification`;
  return Axios.post(url, data);
};

export const sendForm = (data) => {
  const url = `/form/submit`;
  console.log(data);
  return Axios.post(url, data);
};

export const dataForm = (pointUuid) => {
  const url = `/application/${pointUuid}`;
  return Axios.get(url);
};
