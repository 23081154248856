import Vue from 'vue';
import VueRouter from 'vue-router';

import VueI18n from '@/i18n';

Vue.use(VueRouter);
Vue.use(VueI18n);
const routes = [
  {
    path: '/',
    component: () => import('@/components/Main/Main.vue'),
    children: [
      {
        path: '/:lang?',
        name: 'Main',
        component: () => import('@/components/Layout/Content/Content.vue'),
      },
      {
        path: '/:lang?/application/:uuid',
        name: 'FormApplication',
        component: () =>
          import('@/components/FormApplication/FormApplication.vue'),
        props: true,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: `${process.env.BASE_URL}`,
  routes,
});

router.beforeEach((to, from, next) => {
  let defaultLang = VueI18n.locale;
  let lang =
    typeof to.params.lang !== 'undefined' ? to.params.lang : defaultLang;
  VueI18n.locale = lang;
  next();
});

export default router;
