import jwt_decode from 'jwt-decode';
import axios from 'axios';
import store from '@/store';
import VueI18n from '@/i18n';

const config = {
  baseURL: process.env.VUE_APP_API_HOST,
  timeout: 60 * 1000, // Timeout
};

const Axios = axios.create(config);

export { Axios };
